import React from 'react';
import { Link } from 'gatsby';

import Layout from '../layout/main';
import Image from '../components/images/image';

// SEO imports
import SEO from '../components/seo';
import ogImage from '../assets/images/og-images/adaptix.jpg';

import IntroBackground from '../components/images/introBackground';

import Fade from 'react-reveal/Fade';

const PageNotFound = () => (
	<Layout>
		<SEO
			title="404 Error | Strong Mind Performance Consulting"
			description="Whoops! It seems that what your're looking for doesn't exist or has been moved."
			image={ogImage}
		/>
		<section id="intro">
			<Fade>
				<IntroBackground />
			</Fade>
			<div className="intro__content">
				<div className="container">
					<Fade>
						<h1 className="shout">404 Error</h1>
						<p className="talk">
							Whoops! It seems that what your're looking for doesn't exist or has been moved.
						</p>
						<Link className="btn btn__accent" to="/">
							Back to home
						</Link>
					</Fade>
				</div>
			</div>
		</section>
	</Layout>
);

export default PageNotFound;
